import { Link } from 'gatsby';
import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';

const Footer: React.FC = () => {
    return (
        <footer className="px-4 max-w-7xl mx-auto pt-20 pb-8 flex flex-col items-center lg:px-6">
            <div className="w-full flex flex-col sm:flex-row sm:flex-wrap mb-8 pb-8 border-b border-blue-100">
                <div className="w-full mb-10 sm:w-1/2 lg:mb-0 lg:w-1/4 pr-4">
                    <img
                        src="../../site/logo.webp"
                        alt="Logo WrapTown - profesjonalne oklejanie samochodów"
                        className="mb-4"
                    />
                    <p className="mb-4">Profesjonalne oklejanie samochodów. Zapraszamy do skorzystania z usług zmiany kolory samochodu, ochrony lakieru folią PPF czy dechromingu elementów. Kościan, Poznań, Leszno, Śrem, Wrocław.</p>
                    <ul className="flex">
                        <li className="mr-4">
                            <Link
                                to="https://www.facebook.com/wraptownpl"
                                target="_blank"
                            >
                                <FaFacebookF />
                            </Link>
                        </li>
                        <li className="mr-4">
                            <Link
                                to="https://twitter.com/wraptownpl"
                                target="_blank"
                            >
                                <FaTwitter />
                            </Link>
                        </li>
                        <li className="mr-4">
                            <Link
                                to="https://www.instagram.com/wraptownpl/"
                                target="_blank"
                            >
                                <FaInstagram />
                            </Link>
                        </li>
                        <li className="mr-4">
                            <a href="mailto:kontakt@wraptown.com.pl">
                                <MdEmail />
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="w-full mb-10 sm:w-1/2 lg:mb-0 lg:w-1/4">
                    <ul>
                        <li className="mb-4 font-bold text-blue-700">
                            Kontakt
                        </li>
                        <li>WrapTown</li>
                        <li>64-000 Kościan, Zawadzkiego 40</li>
                        <li>kontakt@wraptown.com.pl</li>
                        <li>602 135 913</li>
                    </ul>
                </div>
                <div className="w-full mb-10 sm:w-1/2 lg:mb-0 lg:w-1/4">
                    <ul>
                        <li className="mb-4 font-bold text-blue-700">Usługi</li>
                        <Link to="/uslugi/zmiana-koloru-auta">
                            <li>Zmiana koloru</li>
                        </Link>
                        <Link to="/uslugi/dechroming">
                            <li>Dechroming</li>
                        </Link>
                        <Link to="/uslugi/ochrona-lakieru">
                            <li>Ochrona lakieru</li>
                        </Link>
                    </ul>
                </div>
                <div className="w-full sm:w-1/2 lg:w-1/4">
                    <ul>
                        <li className="mb-4 font-bold text-blue-700">Menu</li>
                        <Link to="/">
                            <li>Home</li>
                        </Link>
                        <Link to="/o-nas">
                            <li>O nas</li>
                        </Link>
                        <Link to="/realizacje">
                            <li>Realizacje</li>
                        </Link>
                        <Link to="/uslugi">
                            <li>Usługi</li>
                        </Link>
                        <Link to="/kontakt">
                            <li>Kontakt</li>
                        </Link>
                        <Link to="/blog">
                            <li>Blog</li>
                        </Link>
                    </ul>
                </div>
            </div>
            <div className="text-sm text-center">
                <p className="mb-2">
                    © 2021 - {new Date().getFullYear()} | WrapTown - Wszelkie prawa zastrzeżone
                </p>
                <p>
                    Strona stworzona przez{' '}
                    <Link to="https://adrianfrackowiak.com/" target="_blank">
                        adrianfrackowiak.com
                    </Link>
                </p>
            </div>
        </footer>
    );
};

export default Footer;
