import { Link } from 'gatsby';
import React, { useState, Fragment } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Menu, Transition } from '@headlessui/react';
import { FiChevronDown } from 'react-icons/fi';

const Header: React.FC = () => {
    const [mobile, setMobile] = useState<boolean>(false);

    return (
        <div>
            <header className="relative px-4 max-w-7xl mx-auto flex items-center justify-between h-24 lg:px-6">
                <div>
                    <Link to="/">
                        <img
                            className="w-10/12 lg:w-full"
                            src="../../site/logo.webp"
                            alt="Logo WrapTown - profesjonalne oklejanie samochodów"
                        />
                    </Link>
                </div>
                <nav className="hidden md:block">
                    <ul className="flex space-x-2">
                        <li>
                            <Link
                                className="inline-flex justify-center w-full rounded-md  px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                                to="/"
                            >
                                Home
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="inline-flex justify-center w-full rounded-md  px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                                to="/o-nas"
                            >
                                O nas
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="inline-flex justify-center w-full rounded-md  px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                                to="/realizacje"
                            >
                                Realizacje
                            </Link>
                        </li>
                        <Menu
                            as="div"
                            className="relative inline-block text-left"
                        >
                            <div>
                                <Menu.Button className="inline-flex justify-center items-center w-full rounded-md px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50">
                                    Usługi <FiChevronDown className="ml-2" />
                                </Menu.Button>
                            </div>

                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="py-2">
                                        <Menu.Item>
                                            <Link
                                                className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-50"
                                                to="/uslugi/zmiana-koloru-auta"
                                            >
                                                Zmiana koloru auta
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item>
                                            <Link
                                                className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-50"
                                                to="/uslugi/dechroming"
                                            >
                                                Dechroming
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item>
                                            <Link
                                                className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-50"
                                                to="/uslugi/ochrona-lakieru"
                                            >
                                                Ochrona lakieru
                                            </Link>
                                        </Menu.Item>
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>

                        <li>
                            <Link
                                className="inline-flex justify-center w-full rounded-md px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                                to="/kontakt"
                            >
                                Kontakt
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="inline-flex justify-center w-full rounded-md px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                                to="/blog"
                            >
                                Blog
                            </Link>
                        </li>
                    </ul>
                </nav>
                {!mobile ? (
                    <button
                        onClick={() => setMobile(true)}
                        className="md:hidden"
                    >
                        <FaBars />
                    </button>
                ) : (
                    <button
                        onClick={() => setMobile(false)}
                        className="md:hidden"
                    >
                        <FaTimes />
                    </button>
                )}
            </header>
            {mobile ? (
                <nav className="md:hidden py-8 w-full bg-white flex justify-center items-center rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <ul className="flex flex-col text-center space-y-4 font-bold">
                        <li>
                            <Link
                                className="inline-flex justify-center w-full rounded-md px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                                to="/"
                            >
                                Home
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="inline-flex justify-center w-full rounded-md px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                                to="/o-nas"
                            >
                                O nas
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="inline-flex justify-center w-full rounded-md px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                                to="/realizacje"
                            >
                                Realizacje
                            </Link>
                        </li>
                        <Menu
                            as="div"
                            className="relative inline-block text-left"
                        >
                            <div>
                                <Menu.Button className="inline-flex justify-center items-center w-full rounded-md px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50">
                                    Usługi <FiChevronDown className="ml-2" />
                                </Menu.Button>
                            </div>

                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="bg-white px-4 py-2 flex justify-center items-center rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="py-2 flex flex-col">
                                        <Menu.Item>
                                            <Link
                                                className="inline-flex justify-center rounded-md px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                                                to="/uslugi/zmiana-koloru-auta"
                                            >
                                                Zmiana koloru auta
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item>
                                            <Link
                                                className="inline-flex justify-center rounded-md px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                                                to="/uslugi/dechroming"
                                            >
                                                Dechroming
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item>
                                            <Link
                                                className="inline-flex justify-center rounded-md px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                                                to="/uslugi/ochrona-lakieru"
                                            >
                                                Ochrona lakieru
                                            </Link>
                                        </Menu.Item>
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                        <li>
                            <Link
                                className="inline-flex justify-center w-full rounded-md px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                                to="/kontakt"
                            >
                                Kontakt
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="inline-flex justify-center w-full rounded-md px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                                to="/blog"
                            >
                                Blog
                            </Link>
                        </li>
                    </ul>
                </nav>
            ) : (
                ''
            )}
        </div>
    );
};

export default Header;
