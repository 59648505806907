import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from './footer';
import Header from './header';

interface Props {
    title: string;
    desc: string;
    url: string;
    type: string;
}

const Layout: React.FC<Props> = ({ children, title, desc, url, type }) => {
    return (
        <div className="antialiased min-h-screen text-base">
            <Helmet>
                <html lang="pl" />
                <title>{title}</title>
                <meta name="description" content={desc} />
                <meta property="og:title" content={title} />
                <meta property="og:type" content={type} />
                <meta property="og:image" content="https://site.com/post.jpg" />
                <meta property="og:url" content={url} />
                <meta property="og:locale" content="pl_PL" />
                <meta property="og:site name" content="WrapTown" />
                <link rel="canonical" href={url} />
                <meta
                    name="facebook-domain-verification"
                    content="9ynr0tyl91tys94m2vyesw7mklb9mq"
                />
            </Helmet>
            <Header />
            {children}
            <Footer />
        </div>
    );
};

export default Layout;
